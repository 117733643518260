import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <nav className="bg-gray-800 p-4">
      <div className="w-full flex items-center">
        <div className="text-white text-lg font-bold">Jose M P Guerra</div>
        <div className="ml-auto flex space-x-4">
          <Link to="/" className="text-white">Home</Link>
          <Link to="/resume" className="text-white">Resume</Link>
          <Link to="/blog" className="text-white">Blog</Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;