import React from 'react';

const LandingPage = () => {
  return (
    <div className="flex flex-col items-start justify-start min-h-screen pt-4">
      <div className="p-6 w-full max-w-2xl">
        <h1 className="text-2xl font-bold mb-4">Software & Data Engineer</h1>
        <p className="text-gray-700">Here I share my thoughts about engineering, product and everything in between.</p>
    </div>
  </div>
  );
};

export default LandingPage;