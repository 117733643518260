import React from 'react';
import { jsPDF } from 'jspdf';

const Resume = () => {
  const generatePDF = () => {
    const doc = new jsPDF();
    let y = 10;
    const maxWidth = 180; // Define the maximum width for text wrapping

    const addWrappedText = (doc, text, x, y, maxWidth, lineHeight) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      doc.text(lines, x, y);
      return y + lines.length * lineHeight;
    };

    doc.setFontSize(22);
    doc.text("José Guerra", 10, y);
    y += 10;

    const startX = 10;
    const endX = 218;
    const totalWidth = endX - startX;

    // Text strings
    const linkedinText = "linkedin.com/in/joseempguerra";
    const githubText = "github.com/joseguerr";
    const locationText = "London, UK";
    const separator = " o ";

    // Calculate text widths
    const linkedinWidth = doc.getTextWidth(linkedinText);
    const githubWidth = doc.getTextWidth(githubText);
    const locationWidth = doc.getTextWidth(locationText);
    const separatorWidth = doc.getTextWidth(separator);

    // Calculate the total width of the text and separators
    const totalTextWidth = linkedinWidth + githubWidth + locationWidth + 2 * separatorWidth;

    // Calculate the remaining space to be distributed
    const remainingSpace = totalWidth - totalTextWidth;
    const spaceBetween = remainingSpace / 2; // Two spaces between three entries and two separators

    doc.setFontSize(12);
    doc.textWithLink(linkedinText, startX, y, { url: "https://www.linkedin.com/in/joseempguerra" });
    doc.text(separator, startX + linkedinWidth + spaceBetween, y);
    doc.textWithLink(githubText, startX + linkedinWidth + separatorWidth + spaceBetween, y, { url: "https://github.com/joseguerr" });
    doc.text(separator, startX + linkedinWidth + separatorWidth + githubWidth + 2 * spaceBetween, y);
    doc.text(locationText, startX + linkedinWidth + 2 * separatorWidth + githubWidth + 2 * spaceBetween, y);
    y += 5;

    doc.setLineWidth(0.5);
    doc.line(10, y, 200, y); // horizontal line
    y += 10;

    doc.setFontSize(16);
    doc.text("WORK EXPERIENCE", 10, y);
    y += 5;
    doc.setLineWidth(0.5);
    doc.line(10, y, 200, y); // horizontal line
    y += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("HelloFresh UK", 10, y);
    doc.text("June 2021 – Present", 200, y, { align: 'right' });
    y += 5;

    doc.setFont("helvetica", "italic");
    doc.text("Data Engineer", 10, y);
    doc.text("London, UK", 200, y, { align: 'right' });
    y += 5;

    doc.setFont("helvetica", "normal");
    y = addWrappedText(doc, "• As a Data Engineer within the UK BI & Data Team, I was responsible to design, maintain and optimise scalable services and data pipelines, guarantee the availability and quality of the UK’s data products and improve the Team’s engineering experience and best practices.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "• Collaborated with internal stakeholders (Marketing, Logistics, Packaging) to deliver business initiatives.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "• Lead the design of multiple ETL pipelines with focus on data quality and software reusability.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "  o Implemented a microservices architecture leveraging Kafka, Postgres, Docker and Kubernetes, hosted in AWS to ensure close to real-time throughput and availability in a business-critical operation.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "  o Implemented APIs for some of the services using FastAPI.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "• Collaborated on a library to centralise core functionalities such as S3, Snowflake, Glue, Postgres APIs, Glue schema changes and business-specific functionalities.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "• Migrated data pipelines and data products into Snowflake, using IaC methodology with DBT + Schema Change.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "  o Followed a data medallion architecture with normalised and denormalised layers.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "  o Built a framework to ingest data from Postgres into Snowflake.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "• Collaborated with the Data Platform on the launch of new in-house tools.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "• Guided Junior Engineers and helped conducting technical interviews.", 10, y, maxWidth, 5);

    y += 5;
    doc.setFont("helvetica", "bold");
    doc.text("Hitachi Vantara", 10, y);
    doc.text("Sept. 2019 – May 2021", 200, y, { align: 'right' });
    y += 5;

    doc.setFont("helvetica", "italic");
    doc.text("Software Engineer", 10, y);
    doc.text("Lisbon, Portugal", 200, y, { align: 'right' });
    y += 5;

    doc.setFont("helvetica", "normal");
    y = addWrappedText(doc, "• Developed a video intelligence analytics solution with focus on building data pipelines through Azure Data Factory and Azure Event Hub for real-time data ingestion.", 10, y, maxWidth, 5);
    y = addWrappedText(doc, "• Worked as a Software Engineer on an Ecommerce application. The project followed the MVC design pattern, using multiple APIs and its own SDK.", 10, y, maxWidth, 5);

    doc.save("JoseGuerra_CV.pdf");
  };

  return (
    <div className="flex flex-col items-start justify-start min-h-screen pt-4">
      <div className="p-6 w-full max-w-2xl">
        <h1 className="text-4xl font-bold mb-2">José Guerra</h1>
        <p className="text-lg mb-4">
          <a href="https://www.linkedin.com/in/joseempguerra" target="_blank" className="text-black-500 hover:underline mr-2">LinkedIn</a>
          <span className="mx-2">❖</span> 
          <a href="https://github.com/joseguerr" target="_blank" className="text-black-500 hover:underline mr-2">GitHub</a>
          <span className="mx-2">❖</span> 
          London, UK
        </p>
        <hr className="border-t border-black mb-4" />
        <section className="mb-4">
          <h2 className="text-lg font-semibold mb-2">WORK EXPERIENCE</h2>
          <hr className="border-t border-black mb-4" />
          <div className="mb-4">
            <div className="flex justify-between">
              <h3 className="font-bold">HelloFresh UK</h3>
              <p className="font-bold">June 2021 – Present</p>
            </div>
            <div className="flex justify-between">
              <h3 className="italic">Data Engineer</h3>
              <p className="italic">London, UK</p>
            </div>
            <ul className="list-disc list-inside">
              <li>As a Data Engineer within the UK BI & Data Team, I was responsible to design, maintain and optimise scalable services and data pipelines, guarantee the availability and quality of the UK’s data products and improve the Team’s engineering experience and best practices.</li>
              <li>Collaborated with internal stakeholders (Marketing, Logistics, Packaging) to deliver business initiatives.</li>
              <li>Lead the design of multiple ETL pipelines with focus on data quality and software reusability.
                <ul style={{ listStyleType: 'circle', paddingLeft: '2rem' }}>
                  <li>Implemented a microservices architecture leveraging Python, Airflow, Docker and Kubernetes.</li>
                  <li>Provided on-call support for business-critical pipelines.</li>
                </ul>
              </li>
              <li>Lead the design of a streaming service by following a domain-driven design approach with focus on resilience and monitoring.
                <ul style={{ listStyleType: 'circle', paddingLeft: '2rem' }}>
                  <li>Implemented a microservices architecture leveraging Kafka, Postgres, Docker and Kubernetes, hosted in AWS to ensure close to real-time throughput and availability in a business-critical operation.</li>
                  <li>Implemented APIs for some of the services using FastAPI.</li>
                </ul>
              </li>
              <li>Collaborated on a library to centralise core functionalities such as S3, Snowflake, Glue, Postgres APIs, Glue schema changes and business-specific functionalities.</li>
              <li>Migrated data pipelines and data products into Snowflake, using IaC methodology with DBT + Schema Change.
                <ul style={{ listStyleType: 'circle', paddingLeft: '2rem' }}>
                  <li>Followed a data medallion architecture with normalised and denormalised layers.</li>
                  <li>Built a framework to ingest data from Postgres into Snowflake.</li>
                </ul>
              </li>
              <li>Collaborated with the Data Platform on the launch of new in-house tools.</li>
              <li>Guided Junior Engineers and helped conducting technical interviews.</li>
            </ul>
          </div>
          <div className="mb-4">
            <div className="flex justify-between">
              <h3 className="font-bold">Hitachi Vantara</h3>
              <p className="font-bold">Sept. 2019 – May 2021</p>
            </div>
            <div className="flex justify-between">
              <h3 className="italic">Software Engineer</h3>
              <p className="italic">Lisbon, Portugal</p>
            </div>
            <ul className="list-disc list-inside">
              <li>Developed a video intelligence analytics solution with focus on building data pipelines through Azure Data Factory and Azure Event Hub for real-time data ingestion.</li>
              <li>Worked as a Software Engineer on an Ecommerce application. The project followed the MVC design pattern, using multiple APIs and its own SDK.</li>
            </ul>
          </div>
        </section>
        <button onClick={generatePDF} className="btn btn-primary">Download as PDF</button>
      </div>
    </div>
  );
};

export default Resume;