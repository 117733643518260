import React from 'react';

const Blog = () => {
  return (
    <div className="flex flex-col items-start justify-start min-h-screen pt-4">
      <div className="p-6 w-full max-w-2xl">
        <h1 className="text-2xl font-bold mb-4">Blog</h1>
        <p className="text-gray-700 flex items-center">
          Launching soon
          <span className="ml-2 inline-block w-4 h-4 border-4 border-t-transparent border-solid border-gray-700 rounded-full animate-spin"></span>
        </p>
      </div>
    </div>
  );
};

export default Blog;